<template>
  <div class="div-primary relative mx-auto w-full xl:my-0">
    <div class="mb-4 text-center sm:text-left">
      <h1 class="text-xl font-bold text-main md:text-3xl 2xl:text-4xl">
        {{ $t('dashboard.campaigns.header') }}
      </h1>
      <p class="my-1 text-main">{{ $t('dashboard.campaigns.subHeader') }}</p>
      <p class="mb-5 text-sm opacity-0">.</p>
    </div>
    <div class="mx-auto w-fit sm:ml-0 lg:w-full">
      <h1 class="mt-0 text-center text-xl font-bold text-main sm:text-left">
        {{ $t('dashboard.campaigns.formatsOnHeader') }}
      </h1>
      <div class="mt-1 flex items-center sm:items-start xl:col-span-2" v-if="filteredCampaignChannels.length === 0">
        <span class="mx-auto text-center text-sm text-red-600 sm:ml-0 sm:text-left md:text-base" v-html="$t('dashboard.campaigns.noOnFormats')"></span>
      </div>
      <div class="mt-5 grid gap-2 sm:grid-cols-2 sm:gap-4">
        <div v-for="(channel, index) in filteredCampaignChannels" :key="index" class="mx-auto flex w-full items-center justify-center gap-x-3 sm:ml-0 sm:justify-start">
          <div class="h-fit w-full max-w-[18.75rem]">
            <div class="rounded-2xl bg-slate-100">
              <div class="flex h-8 items-center px-1 pl-3 md:h-12">
                <img v-if="channel.marketingChannel === 'Facebook'" :src="`${$filePath}/previewFormat/meta.png`" alt="eshop" class="mr-2 w-6" />
                <img v-else :src="`${$filePath}/previewFormat/google.png`" alt="eshop" class="mr-2 w-6" />
                <span class="text-[0.62rem] text-main md:text-base" v-html="$t('dashboard.campaigns.' + channel.marketingFormat)"></span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div :class="[campaignChannels.find((item) => item.marketingFormat === 'PRX')?.isActive ? 'hidden opacity-0' : 'block opacity-100', 'mx-auto mb-auto mt-5 h-full cursor-default']">
        <h1 class="mb-5 mt-10 text-center text-xl text-main sm:mt-0 sm:text-left" v-html="$t('dashboard.campaigns.recommendedCampaigns')"></h1>
        <router-link
          :to="{
            name: CAMPAIGNS_ROUTES.FORMATS,
            params: {
              campaign: EnumsMarketingChannel.Google,
            },
          }"
        >
          <div class="mx-auto flex h-8 max-w-[18.75rem] items-center rounded-full bg-slate-100 hover:cursor-pointer hover:opacity-90 sm:ml-0 md:h-12">
            <div class="h-max rounded-full bg-slate-100 pl-3 pr-2">
              <img :src="`${$filePath}/previewFormat/google.png`" alt="eshop" class="w-6" />
            </div>
            <div class="recommended relative h-full w-full rounded-r-2xl">
              <span class="absolute top-1/2 ml-1 -translate-y-1/2 text-[0.62rem] text-white md:text-base" v-html="$t('dashboard.campaigns.PRX')"></span>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useToast } from 'primevue/usetoast';
import { ref, onMounted, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { CAMPAIGNS_ROUTES } from '@/modules/campaigns/enums/RoutesEnum';
import { api } from '@/services/api';
import { useEshopsStore } from '@/stores/eshops';
import {ClientDashboardCampaignStatus, EnumsMarketingChannel} from '../../../../generated/api';

const eshopStore = useEshopsStore();
const toast = useToast();
const { t } = useI18n();
onMounted(async () => {
  await initCampaigns();
});

watch(
  () => eshopStore.selectedEshop.id,
  async () => {
    await initCampaigns();
  }
);
const campaignChannels = ref<ClientDashboardCampaignStatus[]>([]);
const filteredCampaignChannels = ref<ClientDashboardCampaignStatus[]>([]);
const openModal = ref(false);

const statuses = {
  active: 'Aktivní',
  inactive: 'Neaktivní',
  paused: 'Pozastavená',
};

const initCampaigns = async () => {
  try {
    const { data } = await api.clientDashboardGetActiveCampaignFormats();
    campaignChannels.value = data.campaignStatuses || [];
    const order = ['PLA', 'PRX', 'DSA', 'DPA'];
    campaignChannels.value.sort((a, b) => {
      return order.indexOf(a.marketingFormat) - order.indexOf(b.marketingFormat);
    });

    // filter only active campaigns
    filteredCampaignChannels.value = campaignChannels.value.filter((item) => item.isActive);
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  }
};
</script>
<style scoped lang="css">
.recommended {
  background: linear-gradient(180deg, #ff8f29 0%, #ff6887 100%);
}
</style>
