<template>
  <Menubar
    v-if="router.currentRoute.value.name !== CAMPAIGNS_ROUTES.EDIT_STEP"
    class="bg-main"
    :model="menuItems"
    :pt-options="{ mergeProps: true }"
    :pt="{ menu: '!gap-4 py-4', button: '!text-main !bg-white', menubar: '!bg-white', root: '!bg-white', action: '!px-2 !py-2 sm:!justify-center sm:!mx-auto !w-fit !hover-none', content: '!bg-white' }"
  >
    <template #item="{ item, props }">
      <router-link
        v-if="item.route"
        v-slot="{ href, navigate, isActive }"
        :to="{
          name: item.route,
          params: item.pathParams,
        }"
        custom
      >
        <a
          v-if="item.label !== 'Test'"
          :href="href"
          v-bind="props.action"
          @click="navigate"
          :class="[
            'ml-2 flex items-center gap-2 border-b-2 border-white text-main hover:border-main hover:!bg-white hover:!text-main sm:mx-auto sm:ml-0 sm:justify-center',
            {
              'border-b-2 !border-main bg-white': isActive,
            },
          ]"
        >
          <img :src="$filePath + item.src" class="h-6 w-6" alt="logo" />
          <span class="h-full">{{ item.label }}</span>
        </a>
        <div
          v-else
          :class="[
            'relative mt-2 flex w-fit cursor-not-allowed items-center gap-2 border-b-2 border-white pl-2 text-main sm:mx-auto sm:mt-0 sm:justify-center sm:px-5',
            {
              'border-b-2 !border-main bg-white': isActive,
            },
          ]"
        >
          <div class="w-0.5 sm:hidden"></div>
          <img :src="$filePath + item.src" class="h-6 w-6" alt="logo" />
          <span class="h-full">{{ item.label }}</span>
          <div class="absolute h-full w-full rounded-lg bg-gray-300 p-[1.25rem] opacity-60"></div>
          <div class="absolute -top-1.5 right-2 -mr-2 -mt-2 rounded bg-orange-500 px-1 text-xs font-bold text-white">{{ $t('campaigns.comingSoon') }}</div>
        </div>
      </router-link>
    </template>
  </Menubar>
  <RouterView v-slot="{ Component }">
    <template v-if="Component">
      <Suspense timeout="0">
        <template #default>
          <component :is="Component"></component>
        </template>
        <template #fallback>
          <AssetsSkeleton />
        </template>
      </Suspense>
    </template>
  </RouterView>
</template>

<script setup lang="ts">
import Menubar from 'primevue/menubar';
import { RouterView, useRouter } from 'vue-router';
import AssetsSkeleton from "@/modules/campaigns/components/AssetsSkeleton.vue";
import { CAMPAIGNS_ROUTES } from '@/modules/campaigns/enums/RoutesEnum';
import { EnumsMarketingChannel } from '../../../../generated/api';

const router = useRouter();

const menuItems = [
  {
    label: 'Google',
    src: '/logos/Google.png',
    route: CAMPAIGNS_ROUTES.FORMATS,
    pathParams: { campaign: EnumsMarketingChannel.Google },
  },
  {
    label: 'Meta',
    src: '/logos/Meta.png',
    route: CAMPAIGNS_ROUTES.FORMATS,
    pathParams: { campaign: EnumsMarketingChannel.Facebook },
  },
  {
    label: 'Microsoft',
    src: '/logos/MicrosoftLogo.png',
    route: CAMPAIGNS_ROUTES.FORMATS,
    pathParams: { campaign: EnumsMarketingChannel.Microsoft },
  },
];
</script>
