import { NewspaperIcon, PhotoIcon, QueueListIcon } from '@heroicons/vue/24/outline';
import { defineStore } from 'pinia';
import { CAMPAIGNS_ROUTES } from '@/modules/campaigns/enums/RoutesEnum';
import { IStepOne } from '@/modules/campaigns/interfaces/google/PRXWizard/IStepOne';
import { IStepThree } from '@/modules/campaigns/interfaces/google/PRXWizard/IStepThree';
import { IStepTwo } from '@/modules/campaigns/interfaces/google/PRXWizard/IStepTwo';

type step = [IStepOne, IStepTwo, IStepThree];

interface State {
  formatStatus: boolean;
  currentStep: number;
  jsonFileName: string;
  steps: step;
  isDirty: boolean;
  isEdit: boolean;
}

export const useGooglePRXWizardStore = defineStore('googleprxwizard', {
  state: (): State => ({
    formatStatus: false,
    currentStep: 0,
    jsonFileName: '',
    steps: [
      {
        title: 'campaigns.google.prx.editDialogAssets',
        icon: PhotoIcon,
        route: {
          name: CAMPAIGNS_ROUTES.EDIT_STEP,
          params: {
            step: 'assets', // Name of the component
          },
        },
        isValid: false,
        values: {
          logos: [],
          images: [],
        },
      },
      {
        title: 'campaigns.google.prx.editDialogHeadline',
        icon: QueueListIcon,
        route: {
          name: CAMPAIGNS_ROUTES.EDIT_STEP,
          params: {
            step: 'headlines', // Name of the component
          },
        },
        isValid: false,
        values: {
          LongHeadline: null,
          ShortHeadline1: null,
          ShortHeadline2: null,
          ShortHeadline3: null,
          ShortHeadline4: null,
          ShortHeadline5: null,
        },
      },
      {
        title: 'campaigns.google.prx.editDialogDescription',
        icon: NewspaperIcon,
        route: {
          name: CAMPAIGNS_ROUTES.EDIT_STEP,
          params: {
            step: 'descriptions', // Name of the component
          },
        },
        isValid: false,
        values: {
          ShortDescription: null,
          LongDescription1: null,
          LongDescription2: null,
          LongDescription3: null,
          LongDescription4: null,
        },
      },
    ],
    isDirty: false,
    isEdit: false,
  }),
  getters: {
    getCurrentStep(): number {
      return this.currentStep;
    },
    getStepOne(): IStepOne {
      return this.steps[0];
    },
    getStepTwo(): IStepTwo {
      return this.steps[1];
    },
    getStepThree(): IStepThree {
      return this.steps[2];
    },
  },
  actions: {
    resetStepsValidity() {
      for (let i = this.currentStep; i < this.steps.length; i++) {
        if (this.steps[i]) {
          this.steps[i].isValid = false;
        }
      }
    },
    reset() {
      this.$reset();
    },
  },
});
