<template>
  <section>
    <CampaignFormat v-if="router.currentRoute.value.name !== CAMPAIGNS_ROUTES.EDIT_STEP && !loading" :formats="formats" :campaign="props.campaign" @changeCampaignFormatStatus="onChangeCampaignFormatStatus" @updateFormats="onUpdateFormats" />
    <AssetsSkeleton v-if="loading"/>
    <RouterView v-slot="{ Component }">
      <template v-if="Component">
        <Suspense timeout="0">
          <template #default>
            <component :is="Component"></component>
          </template>
          <template #fallback>
            <AssetsSkeleton />
          </template>
        </Suspense>
      </template>
    </RouterView>
  </section>
</template>
<script setup lang="ts">
import { useToast } from 'primevue/usetoast';
import { ref, watch, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { RouterView, useRouter } from 'vue-router';
import AssetsSkeleton from '@/modules/campaigns/components/AssetsSkeleton.vue';
import CampaignFormat from '@/modules/campaigns/components/CampaignFormat.vue';
import { CAMPAIGNS_ROUTES } from '@/modules/campaigns/enums/RoutesEnum';
import { IFormat } from '@/modules/campaigns/interfaces/IFormat';
import { useFacebookDPAWizardStore } from '@/modules/campaigns/stores/facebook/DPA/wizard';
import { useGoogleDSAWizardStore } from '@/modules/campaigns/stores/google/DSA/wizard';
import { useGooglePRXWizardStore } from '@/modules/campaigns/stores/google/PRX/wizard';
import { useMicrosoftBING_DSAWizardStore } from '@/modules/campaigns/stores/microsoft/BING_DSA/wizard';
import { useMicrosoftBING_PRXWizardStore } from '@/modules/campaigns/stores/microsoft/BING_PRX/wizard';
import { translateCampaignStatusFromEnumToBoolean } from '@/modules/campaigns/utils/translateCampaignStatusFromEnumToBoolean';
import { api } from '@/services/api';
import { useEshopsStore } from '@/stores/eshops';
import { EnumsMarketingChannel, EnumsMarketingFormat } from '../../../../generated/api';

const props = defineProps({
  campaign: {
    type: String as PropType<EnumsMarketingChannel>,
    required: false,
    default: EnumsMarketingChannel.Google,
  },
});

const loading = ref(false);

const toast = useToast();
const { t } = useI18n();
const router = useRouter();

const eshopStore = useEshopsStore();
const prxWizardStore = useGooglePRXWizardStore();
const dsaWizardStore = useGoogleDSAWizardStore();
const metaDpaWizardStore = useFacebookDPAWizardStore();
const bingDsaWizardStore = useMicrosoftBING_DSAWizardStore();
const bingPrxWizardStore = useMicrosoftBING_PRXWizardStore();

const googleFormats = [
  {
    type: EnumsMarketingFormat.Pla,
    name: t('campaigns.googleShopping'),
    imageUrl: '/campaignFormats/pla.png',
  },
  {
    type: EnumsMarketingFormat.Prx,
    name: t('campaigns.performanceMax'),
    imageUrl: '/campaignFormats/prx.png',
    stateStore: prxWizardStore,
  },
  {
    type: EnumsMarketingFormat.Dsa,
    name: t('campaigns.google.dsa.editTitle'),
    imageUrl: '/campaignFormats/dsa.png',
    stateStore: dsaWizardStore,
  },
];

const metaFormats = [
  {
    type: EnumsMarketingFormat.Dpa,
    name: t('campaigns.meta.dpa.editTitle'),
    imageUrl: '/campaignFormats/dsa.png',
    stateStore: metaDpaWizardStore,
  },
];

const microsoftFormats = [
  {
    type: EnumsMarketingFormat.BingPla,
    name: t('campaigns.microsftShopping'),
    imageUrl: '/campaignFormats/pla.png',
  },
  {
    type: EnumsMarketingFormat.BingPrx,
    name: t('campaigns.microsoft.prx.editTitle'),
    imageUrl: '/campaignFormats/prx.png',
    stateStore: bingPrxWizardStore,
  },
  {
    type: EnumsMarketingFormat.BingDsa,
    name: t('campaigns.microsoft.dsa.editTitle'),
    imageUrl: '/campaignFormats/dsa.png',
    stateStore: bingDsaWizardStore,
  },
];

const formats = ref();

const initData = async () => {
  loading.value = true;
  await setFormats();
  await loadFormatsInfo();
};

const setFormats = async () => {
  switch (props.campaign) {
    case EnumsMarketingChannel.Google:
      formats.value = googleFormats;
      break;
    case EnumsMarketingChannel.Facebook:
      formats.value = metaFormats;
      break;
    case EnumsMarketingChannel.Microsoft:
      formats.value = microsoftFormats;
      break;
    default:
      formats.value = [];
  }
};

const getFormatStatus = async (format) => {
  try {
    const { data: campaignStatus } = await api.clientCampaignsGetStatus(format);
    return { status: translateCampaignStatusFromEnumToBoolean(campaignStatus.desiredStatus), problem: campaignStatus.problemDetail };
  } catch (error) {
    toast.add({ severity: 'error', summary: t('campaigns.mistake'), detail: t('campaigns.noDataError') });
    return { status: false, problem: 'error' };
  }
};

const getDailyBudget = async (format) => {
  try {
    const { data } = await api.clientCampaignsGetDailyBudget(format);
    return data;
  } catch (error) {
    toast.add({ severity: 'error', summary: t('campaigns.mistake'), detail: t('campaigns.noDataError') });
    return null;
  }
};

const getCampaignExist = async (format) => {
  try {
    const { data } = await api.clientCampaignsGetCampaignStatus(format);
    return data.campaignExists;
  } catch (error) {
    toast.add({ severity: 'error', summary: t('campaigns.mistake'), detail: t('campaigns.noDataError') });
    return false;
  }
};

const loadFormatsInfo = async () => {
  try {
    const formatStatuses = await Promise.all(formats.value.map((format) => getFormatStatus(format.type)));
    const dailyBudgets = await Promise.all(formats.value.map((format) => getDailyBudget(format.type)));
    const campaignExists = await Promise.all(formats.value.map((format) => getCampaignExist(format.type)));

    formats.value.forEach((format, index) => {
      format.formatStatus = formatStatuses[index].status;
      format.problemDetail = formatStatuses[index].problem;
      format.budget = dailyBudgets[index];
      format.isCampaignExist = format.type === EnumsMarketingFormat.Pla || format.type === EnumsMarketingFormat.BingPla ? true : campaignExists[index];

      let campaignFormats;

      switch (props.campaign) {
        case EnumsMarketingChannel.Google:
          campaignFormats = googleFormats;
          break;
        case EnumsMarketingChannel.Facebook:
          campaignFormats = metaFormats;
          break;
        case EnumsMarketingChannel.Microsoft:
          campaignFormats = microsoftFormats;
          break;
        default:
          break;
      }

      if (campaignFormats[index] && campaignFormats[index].stateStore) {
        campaignFormats[index].stateStore.formatStatus = formatStatuses[index].status;
        campaignFormats[index].stateStore.isEdit = format.isCampaignExist;
      }
    });

  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  } finally {
    loading.value = false;
  }
};

await initData();

const onChangeCampaignFormatStatus = async (format: IFormat) => {
  const index = formats.value.findIndex((item) => item.type === format.type);
  formats.value[index].formatStatus = format.formatStatus;
  formats.value[index].problemDetail = format.problemDetail;

  let campaignFormats;

  switch (props.campaign) {
    case EnumsMarketingChannel.Google:
      campaignFormats = googleFormats;
      break;
    case EnumsMarketingChannel.Facebook:
      campaignFormats = metaFormats;
      break;
    case EnumsMarketingChannel.Microsoft:
      campaignFormats = microsoftFormats;
      break;
    default:
      break;
  }

  if (campaignFormats[index] && campaignFormats[index].stateStore) {
    campaignFormats[index].stateStore.formatStatus = format.formatStatus;
  }
};

const onUpdateFormats = async (format: IFormat) => {
  const index = formats.value.findIndex((item) => item.type === format.type);
  formats.value[index] = format;
};

watch(
  () => eshopStore.selectedEshop.id,
  async () => {
    await initData();
  }
);

watch(
  () => props.campaign,
  async () => {
    await initData();
  }
);
</script>
