<template>
  <div class="mx-auto overflow-auto md:max-w-[70vw] lg:max-w-[78vw]">
    <TreeTable v-if="treeTableData?.categoriesData" :value="treeTableData.categoriesData" :size="`small`" class="p-treetable-sm" :pt-options="{ mergeProps: true }" :pt="{ headerRow: `!text-xs` }" showGridlines stripedRows :loading="loadingTable">
      <Column field="name" :header="$t('productPrioritization.categoryNameLabel')" expander style="width: 25rem">
        <template #body="slotProps">
          <span @click="$emit('openProducts', slotProps.node.data.name)" class="cursor-pointer text-xs hover:underline">{{ slotProps.node.data.name }}</span>
        </template>
      </Column>
      <Column field="defaultAdvGoogle" :header="$t('productPrioritization.categoryAdvertisedGoogle')" style="width: 10rem">
        <template #body="slotProps">
          <Dropdown
            @change="changeAdvertiseStatus(slotProps.node.data.id, slotProps.node.data.defaultAdvGoogle, 'google')"
            v-model="slotProps.node.data.defaultAdvGoogle"
            :options="advertisingStatuses"
            optionLabel="label"
            optionValue="value"
            placeholder=""
            class="w-full"
            :pt-options="{ mergeProps: true }"
            :pt="{
              input: () => ({
                class: ['sm:!p-1 !text-xs'],
              }),
              item: ({ context }) => ({
                class: [
                  '!p-1 !py-2',
                  'dark:text-white/80 dark:hover:bg-gray-800',
                  'hover:text-gray-700 hover:bg-gray-200',
                  {
                    'text-gray-700': !context.focused && !context.selected,
                    'bg-gray-300 text-gray-700 dark:text-white/80 dark:bg-mainDark': context.focused && !context.selected,
                    'bg-blue-50 text-blue-700 dark:bg-blue-400 dark:bg-mainDark': context.focused && context.selected,
                    'bg-blue-50 text-blue-700 dark:bg-blue-300 dark:bg-mainDark': !context.focused && context.selected,
                  },
                ],
              }),
              list: '!py-2 !text-xs',
            }"
          />
        </template>
      </Column>
      <Column field="defaultAdvFacebook" :header="$t('productPrioritization.categoryAdvertisedFacebook')" style="width: 10rem">
        <template #body="slotProps">
          <Dropdown
            @change="changeAdvertiseStatus(slotProps.node.data.id, slotProps.node.data.defaultAdvFacebook, 'facebook')"
            v-model="slotProps.node.data.defaultAdvFacebook"
            :options="advertisingStatuses"
            optionLabel="label"
            optionValue="value"
            placeholder=""
            class="w-full"
            :pt-options="{ mergeProps: true }"
            :pt="{
              input: () => ({
                class: ['sm:!p-1 !text-xs'],
              }),
              item: ({ context }) => ({
                class: [
                  '!p-1 !py-2',
                  'dark:text-white/80 dark:hover:bg-gray-800',
                  'hover:text-gray-700 hover:bg-gray-200',
                  {
                    'text-gray-700': !context.focused && !context.selected,
                    'bg-gray-300 text-gray-700 dark:text-white/80 dark:bg-mainDark': context.focused && !context.selected,
                    'bg-blue-50 text-blue-700 dark:bg-blue-400 dark:bg-mainDark': context.focused && context.selected,
                    'bg-blue-50 text-blue-700 dark:bg-blue-300 dark:bg-mainDark': !context.focused && context.selected,
                  },
                ],
              }),
              list: '!py-2 !text-xs',
            }"
          />
        </template>
      </Column>
      <!-- MICROSOFT WAITING FOR BE
      <Column field="defaultAdvMicrosoft" :header="$t('productPrioritization.categoryAdvertisedMicrosoft')" style="width: 10rem">
        <template #body="slotProps">
          <Dropdown
            @change="changeAdvertiseStatus(slotProps.node.data.id, slotProps.node.data.defaultAdvFacebook, 'microsoft')"
            v-model="slotProps.node.data.defaultAdvMicrosoft"
            :options="advertisingStatuses"
            optionLabel="label"
            optionValue="value"
            placeholder=""
            class="w-full"
            :pt-options="{ mergeProps: true }"
            :pt="{
              input: () => ({
                class: ['sm:!p-1 !text-xs'],
              }),
              item: ({ context }) => ({
                class: [
                  '!p-1 !py-2',
                  'dark:text-white/80 dark:hover:bg-gray-800',
                  'hover:text-gray-700 hover:bg-gray-200',
                  {
                    'text-gray-700': !context.focused && !context.selected,
                    'bg-gray-300 text-gray-700 dark:text-white/80 dark:bg-mainDark': context.focused && !context.selected,
                    'bg-blue-50 text-blue-700 dark:bg-blue-400 dark:bg-mainDark': context.focused && context.selected,
                    'bg-blue-50 text-blue-700 dark:bg-blue-300 dark:bg-mainDark': !context.focused && context.selected,
                  },
                ],
              }),
              list: '!py-2 !text-xs',
            }"
          />
        </template>
      </Column> -->
      <!--<Column field="currentAdvGoogle" header="currentAdvGoogle" style="width: 10rem"></Column>
    <Column field="currentAdvFacebook" header="currentAdvFacebook" style="width: 10rem"></Column>-->
    </TreeTable>
  </div>
</template>
<script setup lang="ts">
import Column from 'primevue/column';
import Dropdown from 'primevue/dropdown';
import TreeTable from 'primevue/treetable';
import { useToast } from 'primevue/usetoast';
import { onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { api } from '@/services/api/index';
import { useEshopsStore } from '@/stores/eshops';
import { useLoadingStore } from '@/stores/loading';

const { t } = useI18n();
const eshopStore = useEshopsStore();
const treeTableData = ref();
const loadingStore = useLoadingStore();
const loadingTable = ref(false);
const toast = useToast();

onMounted(async () => {
  await getData();
});

watch(
  () => eshopStore.selectedEshop.id,
  async () => {
    await getData();
  }
);

const advertisingStatuses = ref([
  { label: t('productPrioritization.categoryAdvertised'), value: true },
  { label: t('productPrioritization.categoryDontAdvertised'), value: false },
]);

const getData = async () => {
  loadingStore.updateLoading(true);
  try {
    loadingTable.value = true;
    const { data } = await api.clientProductsGetCategoriesData();
    treeTableData.value = data;
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  } finally {
    loadingTable.value = false;
    loadingStore.updateLoading(false);
  }
};

const changeAdvertiseStatus = async (itemId, advertiseStatus, advertiseType) => {
  loadingStore.updateLoading(true);
  try {
    const payload = {
      id: itemId,
      advertise: advertiseStatus,
      platform: advertiseType,
    };
    await api.clientProductsSetCategoryAdvertisement(payload);
    toast.add({
      severity: 'success',
      summary: t('productPrioritization.categoryChangeAdvertiseStatusSuccess'),
      life: 6000,
    });
    await getData();
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  } finally {
    loadingStore.updateLoading(false);
  }
};
</script>
